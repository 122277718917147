import { projects, certification, resume } from "../static/data";
import ResumeFile from '../assets/resume/resume.pdf';


const commands = new Map();

const helpCommands = new Map([
  ["modal", "Displays the modal text."],
  ["whoami", "Displays the current user."],
  ["about", "Displays information about Rojan Acharya."],
  ["back", "Back to the initial page."],
  ["date", "Displays the current date and time."],
  ["projects", "Displays a list of projects."],
  ["certifications", "Displays a list of certifications."],
  ["github", "Opens GitHub profile."],
  ["linkedin", "Opens LinkedIn profile."],
  ["email", "Sends an email."],
  ["whatsapp", "Connects to whatsapp"],
  ["twitter", "Connects to twitter profile"],
  ["connect", "Lists available social commands."],
  ["skills", "Displays the technology stack."],
  ["help", "Displays this help message."],
  ["clear", "Clears the command data"],
  ["language", "Shows languages"],
]);


commands.set("modal", modalText());
commands.set("whoami", "root");
commands.set("about", aboutText());
commands.set("resume", resumeViewText(resume));
commands.set("back", "/");
commands.set("date", new Date().toLocaleString());
commands.set("projects", projectsText());
commands.set("certifications", certificationText());
commands.set("github", openLink("https://github.com/racharya404"));
commands.set("linkedin", openLink("https://www.linkedin.com/in/racharya404"));
commands.set("email", openLink("mailto:rojanacharya404@gmail.com"));
commands.set("whatsapp", openLink("https://api.whatsapp.com/send?phone=9864594217"));
commands.set("twitter", openLink("https://twitter.com/Rojan_Acharya01"));
commands.set(
  "connect",
  "Usage: [command]<br><br>github, linkedin, email, whatsapp, twitter"
);
commands.set("skills", techStack());
commands.set("language", language());
commands.set("help", helpText());

function getCommandByName(name) {
  name = name.trim().toLowerCase().split(" ")[0];

  switch (name) {
    case "github":
      window.open("https://github.com/racharya404", "_blank");
      break;
    case "linkedin":
      window.open("https://www.linkedin.com/in/racharya404", "_blank");
      break;
    case "email":
      window.open("mailto:rojanacharya404@gmail.com", "_blank");
      break;
  }

  return commands.get(name) ?? `${name}: command not found <br/>Type 'help' to see the list of available commands.`;
}


function getCommandNames() {
  const commandNames = ["clear"];
  for (const entry of Array.from(commands.entries())) {
    commandNames.push(entry[0]);
  }

  return commandNames.sort();
}

function modalText() {
  return `
    Welcome to personal portfolio of Rojan Acharya<br>

    <br>&nbsp;* GitHub: 
    <a class="terminal-link" href="https://github.com/racharya404" target="_blank" rel="noreferrer">
      https://github.com/racharya404
    </a>
    <br>
    <br>&nbsp;* Type 'help' to see the list of available commands.
  `;
}
function language() {
  return `  
    <br>&nbsp;* English (Full Working Proficiency)<br>
    <br>&nbsp;* Nepali (Native or Bilingual Proficiency)<br>
    <br>&nbsp;* Hindi (Full Working Proficiency)<br>
  `;
}

function resumeViewText(resume) {
  return `
    Resume of Rojan Acharya. Click to view <br>
    &nbsp;* Resume: 
    <a class="resume-link" href="${resume.resume_link}" target="_blank" rel="noreferrer">
      ${resume.resume_link}
    </a>
    <br>
    <br>&nbsp;* Type 'downres' to see the list of available commands.
  `;
}



function helpText() {
  const maxLength = Math.max(...Array.from(helpCommands.keys()).map(cmd => cmd.length));
  const padding = 2; // Number of spaces between the command and its description

  const commandDescriptions = Array.from(helpCommands.entries()).map(
    ([command, description]) =>
      `${command.toUpperCase().padEnd(maxLength + padding)} => ${description} `
  );

  return `
    ${commandDescriptions.sort().join("<br>")}
  `;
}


function aboutText() {
  return `
  Hello, root!
     <br>
      I am Rojan Acharya, a software developer based in Bhaktapur, Nepal.
      <br><br>
        I'm always picking up new skills and adjusting to new technologies, and I'm constantly searching for ways to advance my career and get involved in interesting projects. Experience with Backend(Node.js,Express.js and MongoDB as a database), Frontend(ReactJS and Redux for state management)

        Would you like to connect with me? Enter the 'socials' command!<br>
          `;
}

function openLink(link) {
  return `
          Redirecting to
          <a class="terminal-link" href="${link}" target="_blank" rel="noreferrer">
            ${link}</a>...
          `;
}

function projectsText() {
  return `
          ${projects
      .map((project) => {
        return `
        <a 
          class="project-${project.id}"
          href="${project.project_link}"
          target="_blank"
          rel="noreferrer"
        ><br>#${project.id}:${project.project_title}<br>
        Description: ${project.project_desc}<br>
        </a>`;
      })
      .join("&nbsp;&nbsp;&nbsp;")}
          `;
}

function certificationText() {
  return `
          ${certification
      .map((certification) => {
        return `
        <div 
          class="certification-${certification.id}"
        ><a
        class="certification-${certification.id}"
          href="${certification.certifications_link}"
          target="_blank"
          rel="noreferrer">#${certification.id}:${certification.certifications_title}</a>
        </div>`;
      })
      .join("&nbsp;&nbsp;&nbsp;")}
          `;
}
function techStack() {
  return `<img src="https://github-readme-tech-stack.vercel.app/api/cards?title=Tech+Stack&width=420&align=center&titleAlign=center&fontSize=20&lineHeight=10&lineCount=3&theme=racharya404&line1=node.js%2Cnode.js%2Cauto%3Bexpress%2Cexpress%2Cffffff%3Bnestjs%2Cnestjs%2Ce12a54%3B&line2=react%2Creact%2Cauto%3Btailwindcss%2Ctailwind%2Cauto%3Btypescript%2Ctypescript%2Cauto%3B&line3=Next.js%2Cauto%3Bprisma%2CPrisma%2Cauto%3Bmysql%2CMySQL%2Cauto%3Bmongodb%2CMongoDB%2Cauto%3B" alt="Tech Stack" /> `;
}

export { commands, getCommandByName, getCommandNames, helpText, aboutText, projectsText, language, techStack, modalText };

