import { getCommandByName } from "../const/commands.js";

export default class PromptSession {
    constructor() {
        this.promptText = "";
        this.showCursor = true;
        this.enterPressed = false;
    }

    handleEnterClick(text) {
        this.enterPressed = true;
        this.promptText = text;
        this.showCursor = false;

        if (this.promptText.trim() !== "") {
            this.result = getCommandByName(this.promptText);
        }
    }
}
