import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Navbar from '../components/Home/Navbar/Navbar';
import HeroSection from '../components/Home/HeroSection/HeroSection';
import About from '../components/Home/About/About';
import Skills from '../components/Home/Skills/Skills';
import Projects from '../components/Home/Projects/Projects';
import Contact from '../components/Home/Contact/Contact';
import Footer from '../components/Home/Footer/Footer';
import Scrollup from '../components/Home/Scrollup/Scrollup';
import Certification from '../components/Home/Certification/Certification';
import TerminalContainer from '../terminal/TerminalContainer';
import Modal from '../components/modal';
import TopParent from '../layout/main';
import CustomTrailCanvas from "../utils/custom-trail-canvas.jsx"
import CustomCursor from "../utils/custom-cursor.js"

const Main = styled.div`
  background-color: ${(props) =>
    !props.light ? 'hsl(355, 100%, 7%)' : 'hsl(219, 100%, 99%)'};
  position: relative;
  min-height: 100vh;
  overflow: ${(props) => (props.isTerminalOpen ? 'hidden' : 'auto')};
`;

const Home = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [isTerminalOpen, setIsTerminalOpen] = useState(false);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia(
      '(prefers-color-scheme: light)'
    ).matches;
    setDarkMode(prefersDarkMode);
  }, []);

  useEffect(() => {
    // Update body overflow style based on terminal state
    document.body.style.overflow = isTerminalOpen ? 'hidden' : 'auto';
  }, [isTerminalOpen]);

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleTerminalButtonClick = (data) => {
    setIsTerminalOpen(data);
    console.log(data);
  };

  useEffect(() => {
    const prefersLightMode = window.matchMedia('(prefers-color-scheme: light)').matches;
    setDarkMode(prefersLightMode);
  }, []);

  const handleCloseModal = () => {
    setIsTerminalOpen(false);
  };

  return (
    <div className='bg-background'>
      <TopParent>
        <Navbar
          handleDarkMode={handleDarkMode}
          darkMode={darkMode}
          handleTermnialOpen={handleTerminalButtonClick}
        />
        <Main light={!darkMode} isTerminalOpen={isTerminalOpen}>
          <HeroSection darkMode={darkMode} />
          <About darkMode={darkMode} />
          <Skills darkMode={darkMode} />
          <Projects darkMode={darkMode} />
          <Certification darkMode={darkMode} />
          <Contact darkMode={darkMode} />
          <Footer />
        </Main>
        <Scrollup darkMode={darkMode} />
        {isTerminalOpen && (
          <Modal onClose={handleCloseModal}>
            <CustomTrailCanvas className="pointer-events-none fixed inset-0 h-full w-full" />
            <TerminalContainer handleTerminalChange={handleTerminalButtonClick} />
          </Modal>
        )}
      </TopParent>
    </div>
  );
};

export default Home;
