import React from 'react';
import useDeviceInfo from '../hooks/useDeviceInfo';

const MotdComponent = () => {
    const { deviceInfo } = useDeviceInfo();

    if (!deviceInfo) {
        return <div>Loading device info...</div>;
    }


    return (
        <div>
            <p>
                Microsoft Windows [{deviceInfo.osVersion}]
            </p>
            <p>
                (c) Microsoft Corporation. All rights reserved.
            </p>
            <br />
        </div>
    );
};

export default MotdComponent;
