import React, { useState, useCallback } from "react";
import { getCommandNames } from "../const/commands";
import useDeviceInfo from "../hooks/useDeviceInfo"

const Prompt = (props) => {
  const { deviceInfo } = useDeviceInfo();
  const [cmdNames] = useState(getCommandNames());

  const formatText = (text) => {
    const split = text.split(" ");

    if (cmdNames.includes(split[0])) {
      split[0] = `<span class="text-kali-green-dark relative">${split[0]}</span>`;
    }

    split[0] = `
      ${split[0]}<span class="text-kali-text-muted/[.75] absolute">
        ${getAutoCompleteText(text.trim())}
      </span>
    `;

    return split.join("&nbsp;");
  };

  const getAutoCompleteText = useCallback(
    (text) => {
      if (text === "") {
        return "";
      }

      const filtered = [...cmdNames].filter((cmd) => cmd.startsWith(text));
      if (text === filtered[0]) {
        return "";
      }

      return filtered.length > 0 ? filtered[0].slice(text.length) : "";
    },
    [cmdNames]
  );

  if (!deviceInfo) return <div>Unable to fetch device info</div>;


  return (
    <div className="flex flex-col relative">
      <div
        className=" before:content[''] before:h-[2px] before:w-5 
          before:absolute before:top-1/4 before:left-0.5
          after:content[''] after:h-1/2 after:w-[2px]
          after:absolute after:left-0.5 after:translate-y-1/2 
          before:-translate-y-[2px] select-none font-bold"
      >
        <span className="text-green-500">root{"    "}</span>
        <span className="text-kali-red">@{"    "}</span>
        <span className="text-yellow-300">/d/racharya404/portfolio{" "}</span>
        <span className="text-blue-600">({deviceInfo.model})</span>
      </div>

      <div
        className="before:content[''] before:h-[2px] before:w-3 
           before:absolute before:top-[75%] before:left-0.5
          before:-translate-y-[2px] flex items-center gap-0"
      >
        <span className="text-kali-red select-none font-bold">$</span>

        <div
          className={`text-kali-gray text-sm relative 
            ${props.showCursor &&
            `after:content[''] after:h-4 after:w-2 after:absolute 
              after:bg-kali-gray/[.8] after:translate-y-[10%] 
              after:animate-blink after:text-black`
            }`}
        >
          <span
            className="ml-2"
            dangerouslySetInnerHTML={{
              __html: formatText(props.text),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Prompt;
