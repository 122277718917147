import React from 'react';
import {
  FooterContainer,
  FooterCopy,
  FooterLink,
  FooterList,
  FooterMain,
  FooterSocial,
  FooterSocialLink,
  FooterTitle,
} from './FooterElements';
import Logo from '../../../assets/images/logo1.jpeg';

const Footer = () => {
  return (
    <FooterMain>
      <FooterContainer>
        <FooterList>
          <img
            src={Logo}
            alt="logo"
            height={40}
            width={40}
            className="border border-blue-500 rounded-full"
          />
          <li>
            <FooterLink to="about">About</FooterLink>
          </li>

          <li>
            <FooterLink to="contact">Contact</FooterLink>
          </li>
        </FooterList>

        <FooterSocial>
          <div className="text-xl items-center">Find me on:</div>
          <FooterSocialLink
            href="https://www.linkedin.com/in/racharya404"
            target={'_blank'}
          >
            <i className="bx bxl-linkedin-square"></i>
          </FooterSocialLink>

          <FooterSocialLink
            href="https://github.com/racharya404"
            target={'_blank'}
          >
            <i className="bx bxl-github"></i>
          </FooterSocialLink>

          <FooterSocialLink
            href="https://www.reddit.com/user/Official_Rojan"
            target={'_blank'}
          >
            <i className="bx bxl-reddit"></i>
          </FooterSocialLink>
          <FooterSocialLink
            href="https://twitter.com/Rojan_Acharya01"
            target={'_blank'}
          >
            <i className="bx bxl-twitter"></i>
          </FooterSocialLink>
        </FooterSocial>
        {/* <FooterTitle>
          👉Personal Portfolio of Rojan Acharya.
          <br /> 👉Made using ReactJS
        </FooterTitle> */}
        <FooterCopy>
          Copyright &#169; {new Date().getFullYear()} Rojan Acharya. All rights
          reserved.
        </FooterCopy>
      </FooterContainer>
    </FooterMain>
  );
};

export default Footer;
