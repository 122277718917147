import React, { useEffect, useState } from "react";
import { BsTerminal } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { CiBatteryCharging } from "react-icons/ci";

const TerminalTitle = (props) => {
  const { batteryInfo } = useDeviceInfo();

  const [batteryLevel, setBatteryLevel] = useState(null)

  const [ipAddress, setIpAddress] = useState("")

  const fetchIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      setIpAddress(data.ip)
    } catch (error) {
      console.error("Ip not found", error)
    }
  }

  useEffect(() => {
    fetchIp()
  }, [])

  useEffect(() => {
    if (batteryInfo)
      setBatteryLevel(batteryInfo.batteryLevel)
    // This effect will re-run whenever batteryInfo.batteryLevel or batteryInfo.isCharging changes
  }, [batteryInfo]);

  if (!batteryInfo) return <div>Battery info not available</div>



  return (
    <div
      className="bg-kali-black/[.70] rounded-tl-md rounded-tr-md 
        text-kali-gray font-segoe font-semibold flex items-center 
        justify-between py-2.5 px-4 border-b-2 border-solid border-black/[.4]
        select-none shadow-sm relative"
    >
      <div className="text-base flex gap-1">
        <BsTerminal />
      </div>

      <div className="w-fit h-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        root @ /d/racharya404/portfolio
      </div>

      <div className="text-sm flex items-center justify-center gap-2">
        <div className="text-xs  flex items-center justify-center gap-2 pr-2 border-r-2 border-gray-200">
          <div className="cursor-pointer text-white rounded-full font-normal transition-all duration-150 text-sm relative">
            {ipAddress}
          </div>
        </div>
        <div className="flex font-normal items-center justify-center">
          <CiBatteryCharging size={20} />
          <span className="text-xs text-center">
            {typeof batteryInfo.batteryLevel === 'number' && batteryInfo.batteryLevel >= 0 && batteryInfo.batteryLevel <= 1
              ? `${(batteryLevel * 100)}%`
              : 'Invalid battery level'}
          </span>
        </div>

        <div
          className="w-4 h-4 bg-kali-gray-dark border 
            border-solid border-black/[.3] rounded-full"
        />

        <div
          className="w-4 h-4 bg-kali-gray-dark border 
            border-solid border-black/[.3] rounded-full"
        />


        <button
          type="button"
          onClick={props.closeTerminal}
          className="cursor-pointer text-black w-4 h-4 bg-kali-blue-bg 
            border border-solid border-black/[.5] rounded-full
            font-bold hover:bg-kali-blue hover:text-kali-black
            transition-all duration-150 text-base relative"
        >
          <button className="w-fit h-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <GrFormClose />
          </button>
        </button>
      </div>
    </div >
  );
};

export default TerminalTitle;
