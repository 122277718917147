import React from 'react';
import { Button } from '../../ButtonElements';
import {
  ButtonCV,
  HeroButtons,
  HeroContainer,
  HeroData,
  HeroEducation,
  HeroGreeting,
  HeroImg,
  HeroName,
  HeroScroll,
  HeroScrollName,
  HeroSectionMain,
  HeroSocial,
  HeroSocialLink,
} from './HeroSectionElements';
import rojanhere from '../../../assets/images/rojan.jpeg';
import { user } from '../../../static/data';
import { resume } from '../../../static/data';

const HeroSection = ({ darkMode }) => {
  const openPdfInNewTab = () => {
    window.open(resume.resume_link, '_blank');
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = resume.resume_file;
    link.setAttribute('download', 'resume.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDownloadPdfClick = () => {
    downloadPdf();
  };

  return (
    <HeroSectionMain id="home">
      <HeroContainer>
        <HeroImg src={rojanhere} />
        <HeroData>
          <HeroGreeting light={darkMode ? 1 : 0}>Hello, I'm</HeroGreeting>
          <HeroName light={darkMode ? 1 : 0}>{user.name}</HeroName>
          <HeroEducation light={darkMode ? 1 : 0}>{user.stack}</HeroEducation>
          <HeroButtons>
            <ButtonCV className="hero_ghost" onClick={onDownloadPdfClick}>
              Download Resume
              <i className={`bx bx-download pl-3`}></i>
            </ButtonCV>
            <Button onClick={openPdfInNewTab}>
              View Resume<i className={`bx bx-show-alt pl-3`}></i>
            </Button>
            <Button to="about">
              About Me<i className={`bx bx-user pl-3`}></i>
            </Button>
          </HeroButtons>
          <HeroButtons className='pt-4'>
            <div class="p-3 bg-yellow-300 rounded-2xl border">
              <span class="text-nowrap text-black">Exclusive: Terminal Mode Available</span>
            </div>
          </HeroButtons>
        </HeroData>
        <HeroSocial>
          <HeroSocialLink
            href="https://www.linkedin.com/in/racharya404"
            target={'_blank'}
          >
            <i className="bx bxl-linkedin-square"></i>
          </HeroSocialLink>
          <HeroSocialLink
            href="https://github.com/racharya404"
            target={'_blank'}
          >
            <i className="bx bxl-github"></i>
          </HeroSocialLink>
          <HeroSocialLink
            href="https://www.reddit.com/user/Official_Rojan"
            target={'_blank'}
          >
            <i className="bx bxl-reddit"></i>
          </HeroSocialLink>
          <HeroSocialLink
            href="https://twitter.com/Rojan_Acharya01"
            target={'_blank'}
          >
            <i className="bx bxl-twitter"></i>
          </HeroSocialLink>
        </HeroSocial>

        <HeroScroll to="about">
          <i className="bx bx-mouse home__scroll-icon"></i>
          <HeroScrollName>Scroll Down</HeroScrollName>
        </HeroScroll>
      </HeroContainer>
    </HeroSectionMain>
  );
};

export default HeroSection;
