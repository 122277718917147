const { useCallback, useEffect, useState } = require("react");
const { getCommandNames } = require("../const/commands");

const useKeyboardInput = (enterPress, clearInputs) => {
  const [result, setResult] = useState("");
  const [cmdNames] = useState(getCommandNames());

  const handleKeyPress = useCallback(
    (event) => {
      event.preventDefault();

      if (event.isComposing) {
        return;
      }

      // enter press
      if (event.code === "Enter") {
        enterPress(result);
        setResult("");
        return;
      }

      // tab press (autocomplete)
      if (event.code === "Tab") {
        if (result === "") {
          return;
        }

        const filtered = [...cmdNames].filter((cmd) => cmd.startsWith(result));

        setResult(filtered.length > 0 ? filtered[0] : result);
        return;
      }

      // ctrl + backspace hotkey
      if (event.ctrlKey && event.code === "Backspace") {
        setResult((prev) => prev.split(" ").slice(0, -1).join(" "));
        return;
      }

      // ctrl + l hotkey
      if (event.ctrlKey && event.code === "KeyL") {
        clearInputs();
        return;
      }

      // regular keys
      if (event.code.startsWith("Key") || event.code.startsWith("Digit")) {
        setResult((prev) => `${prev}${event.key}`);
        return;
      }

      // "special characters"
      switch (event.code) {
        case "Backspace":
          setResult((prev) => prev.slice(0, -1));
          break;
        case "Space":
          setResult((prev) => `${prev} `);
          break;
      }
    },
    [enterPress, result, clearInputs, cmdNames]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  return result;
};

module.exports = { useKeyboardInput };
