// utils/custom-trail-canvas.js
import React, { useEffect, useRef } from 'react';
import { cursorTrail } from './cursor-trail'; // Ensure this path is correct

function CustomTrailCanvas(props) {
    const refCanvas = useRef(null);

    useEffect(() => {
        if (!refCanvas.current) return;

        const { cleanUp, renderTrailCursor } = cursorTrail({
            ref: refCanvas,
            color: ' hsla(344, 100%, 57%, 1)', // Use your desired color
        });

        renderTrailCursor();

        return () => {
            cleanUp();
        };
    }, []);

    return (
        <canvas
            ref={refCanvas}
            className={props.className}
            style={{ ...props.style, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 3 }}
        />
    );
}

export default CustomTrailCanvas;
