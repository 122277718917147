import { useState, useEffect } from 'react';
import { Device } from '@capacitor/device';

const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [batteryInfo, setBatteryInfo] = useState(null);

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      try {
        const info = await Device.getInfo();
        const battery = await Device.getBatteryInfo();
        setDeviceInfo(info);
        setBatteryInfo(battery);
      } catch (error) {
        console.error('Error fetching device info:', error);
      }
    };

    fetchDeviceInfo();
  }, []);

  return { deviceInfo, batteryInfo };
};

export default useDeviceInfo;
