const { useState, useEffect } = require("react");

const deviceNames = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i,
];

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(deviceNames.some((i) => navigator.userAgent.match(i)));
  }, []);

  return isMobile;
};

module.exports = { useIsMobile };
