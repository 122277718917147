import React from 'react';
import { SectionSubtitle, SectionTitle } from '../../SectionTitleElements';
import {
  SkillBox,
  SkillContainer,
  SkillContent,
  SkillData,
  SkillGroup,
  SkillLevel,
  SkillName,
  SkillSection,
} from './SkillsElements';
import { skill } from '../../../static/data';

const Skills = ({ darkMode }) => {
  return (
    <SkillSection id="skills">
      <SectionSubtitle>Technologies I use</SectionSubtitle>
      <SectionTitle>My Skills</SectionTitle>

      <SkillContainer>
        <SkillContent light={darkMode ? 1 : 0}>
          <SkillBox>
            <SkillGroup>
              {skill.map((skill) => (
                <SkillData light={darkMode ? 1 : 0} key={skill.id}>
                  <i className="bx bxs-badge-check"></i>
                  <div>
                    <SkillName light={darkMode ? 1 : 0}>
                      {skill.skill_name}
                    </SkillName>
                    <SkillLevel>{skill.level}</SkillLevel>
                  </div>
                </SkillData>
              ))}
            </SkillGroup>
          </SkillBox>
        </SkillContent>
      </SkillContainer>
    </SkillSection>
  );
};

export default Skills;
