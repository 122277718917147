// utils/CustomCursor.jsx
import React, { useEffect, useRef, useState } from 'react';

const CustomCursor = () => {
    const cursorRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false); // State to track if the cursor is hovering over a link

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (cursorRef.current) {
                cursorRef.current.style.left = `${event.clientX}px`;
                cursorRef.current.style.top = `${event.clientY}px`;
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);

        // Select all links
        const links = document.querySelectorAll('a, button, Button');
        links.forEach((link) => {
            link.addEventListener('mouseenter', handleMouseEnter);
            link.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            links.forEach((link) => {
                link.removeEventListener('mouseenter', handleMouseEnter);
                link.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

    return (
        <div
            ref={cursorRef}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: isHovered ? '25px' : '20px', // Scale up on hover
                height: isHovered ? '25px' : '20px', // Scale up on hover
                borderRadius: '50%',
                border: isHovered ? "4px solid  hsla(164, 100%, 57%, 1)" : '4px solid  hsla(344, 100%, 57%, 1)', // Border color
                backgroundColor: 'transparent',
                pointerEvents: 'none', // Prevent it from interfering with mouse events
                transform: 'translate(-50%, -50%)', // Center the cursor
                zIndex: 4, // Make sure it's above the trail but below other content
                transition: 'width 0.2s, height 0.2s', // Smooth scaling transition
            }}
        />
    );
};

export default CustomCursor;
