import React from 'react';
import { SectionSubtitle, SectionTitle } from '../../SectionTitleElements';
import {
  AboutBox,
  AboutContainer,
  AboutData,
  AboutDesc,
  AboutIcon,
  AboutInfo,
  AboutSection,
  AboutSubTitle,
  AboutTitle,
  Hire,
} from './AboutElements';
import { Button } from '../../ButtonElements';
import { user } from '../../../static/data';
import { projects } from '../../../static/data';
import { certification } from '../../../static/data';

const About = ({ darkMode }) => {
  return (
    <AboutSection id="about">
      <SectionSubtitle>Introducing myself</SectionSubtitle>
      <SectionTitle>About Me</SectionTitle>
      <AboutContainer>
        <AboutData>
          <AboutInfo>
            <AboutBox light={darkMode ? 1 : 0}>
              <AboutIcon>
                <i className="bx bx-globe"></i>
              </AboutIcon>
              <AboutTitle light={darkMode ? 1 : 0}>Experience</AboutTitle>
              <AboutSubTitle light={darkMode ? 1 : 0}>
                {user.experience}+
              </AboutSubTitle>
            </AboutBox>

            <AboutBox light={darkMode ? 1 : 0}>
              <AboutIcon>
                <i className="bx bx-briefcase-alt"></i>
              </AboutIcon>
              <AboutTitle light={darkMode ? 1 : 0}>Completed</AboutTitle>
              <AboutSubTitle light={darkMode ? 1 : 0}>
                {projects.length}+ Projects
              </AboutSubTitle>
            </AboutBox>

            <AboutBox light={darkMode ? 1 : 0}>
              <AboutIcon>
                <i className="bx bx-notepad"></i>
              </AboutIcon>
              <AboutTitle light={darkMode ? 1 : 0}>Certified with</AboutTitle>
              <AboutSubTitle light={darkMode ? 1 : 0}>
                {certification.length}+ Certifications
              </AboutSubTitle>
            </AboutBox>

            <AboutBox light={darkMode ? 1 : 0}>
              <AboutIcon>
                <i className="bx bx-support"></i>
              </AboutIcon>
              <AboutTitle light={darkMode ? 1 : 0}>Support</AboutTitle>
              <AboutSubTitle light={darkMode ? 1 : 0}>
                {user.support}
              </AboutSubTitle>
            </AboutBox>
          </AboutInfo>

          <AboutDesc light={darkMode ? 1 : 0}>{user.description}</AboutDesc>
          <Hire>
            <Button to="contact" className="justify-center">
              Hire Me
            </Button>
          </Hire>
        </AboutData>
      </AboutContainer>
    </AboutSection>
  );
};

export default About;
