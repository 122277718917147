import React, { useRef, useState } from 'react';
import { SectionSubtitle, SectionTitle } from '../../SectionTitleElements';
import {
  ContactButton,
  ContactButtonIcon,
  ContactCard,
  ContactCardData,
  ContactCardIcon,
  ContactCardTitle,
  ContactContainer,
  ContactForm,
  ContactFormDiv,
  ContactFormTag,
  ContactInfo,
  ContactSection,
  ContactTitle,
  ContactFormInput,
  ContactFormArea,
  ContactTextArea,
  ButtonEmail,
} from './ContactElements';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const Contact = ({ darkMode }) => {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);
  const emailServiceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const emailTemplateId = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const emailUserId = process.env.REACT_APP_EMAIL_USER_ID;

  const sendEmail = (e) => {
    e.preventDefault();

    const name = e.target.elements.from_name.value;
    const email = e.target.elements.from_email.value;
    const message = e.target.elements.message.value;

    if (!name || !email || !message) {
      toast.error('Please fill in all fields.');
      return;
    }

    const currentDate = new Date().toISOString().split('T')[0];
    const sentCount = parseInt(localStorage.getItem(`sentCount_${email}`)) || 0;
    const lastSentDate = localStorage.getItem(`lastSentDate_${email}`);

    if (lastSentDate === currentDate && sentCount >= 3) {
      toast.error('You have reached the email sending limit for today.');
      return;
    }

    setIsSending(true);

    emailjs
      .sendForm(emailServiceId, emailTemplateId, e.target, emailUserId)
      .then(
        (result) => {
          console.log(result.text);
          setIsSending(false);
          toast.success('Email sent!👍');

          localStorage.setItem(`sentCount_${email}`, sentCount + 1);
          localStorage.setItem(`lastSentDate_${email}`, currentDate);

          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          setIsSending(false);
          toast.error('Error, please try again!');
        }
      );
  };

  return (
    <ContactSection id="contact">
      <SectionSubtitle>Don't be shy! Hit me up! 👇</SectionSubtitle>
      <SectionTitle>Contact Me</SectionTitle>

      <ContactContainer>
        <div className="contact_content">
          <ContactTitle light={darkMode ? 1 : 0}>Get in touch</ContactTitle>

          <ContactInfo>
            <ContactCard light={darkMode ? 1 : 0}>
              <ContactCardIcon light={darkMode ? 1 : 0}>
                <i className="bx bxl-gmail"></i>
              </ContactCardIcon>
              <ContactCardTitle light={darkMode ? 1 : 0}>
                Email
              </ContactCardTitle>
              <ContactCardData light={darkMode ? 1 : 0}>
                rojanacharya404@gmail.com
              </ContactCardData>
              <ContactButton
                href="mailto:rojanacharya404@gmail.com"
                target={'_blank'}
              >
                Write a mail
                <ContactButtonIcon>
                  <i className="bx bx-right-arrow-alt"></i>
                </ContactButtonIcon>
              </ContactButton>
            </ContactCard>

            <ContactCard light={darkMode ? 1 : 0}>
              <ContactCardIcon light={darkMode ? 1 : 0}>
                <i className="bx bxl-twitter"></i>
              </ContactCardIcon>
              <ContactCardTitle light={darkMode ? 1 : 0}>
                Twitter
              </ContactCardTitle>
              <ContactCardData light={darkMode ? 1 : 0}>
                @Rojan_Acharya01
              </ContactCardData>
              <ContactButton
                href="https://twitter.com/Rojan_Acharya01"
                target={'_blank'}
              >
                Write me
                <ContactButtonIcon>
                  <i className="bx bx-right-arrow-alt"></i>
                </ContactButtonIcon>
              </ContactButton>
            </ContactCard>
          </ContactInfo>
        </div>

        <div>
          <ContactTitle light={darkMode ? 1 : 0}>
            Write me your project
          </ContactTitle>

          <ContactForm ref={form} onSubmit={sendEmail}>
            <ContactFormDiv>
              <ContactFormTag htmlFor="" light={darkMode ? 1 : 0}>
                Your Name
              </ContactFormTag>
              <ContactFormInput
                light={darkMode ? 1 : 0}
                type="text"
                name="from_name"
                placeholder="Enter your name"
                required
              />
            </ContactFormDiv>

            <ContactFormDiv>
              <ContactFormTag htmlFor="" light={darkMode ? 1 : 0}>
                Email
              </ContactFormTag>
              <ContactFormInput
                light={darkMode ? 1 : 0}
                type="email"
                name="from_email"
                placeholder="Enter your email"
                required
              />
            </ContactFormDiv>

            <ContactFormArea>
              <ContactFormTag htmlFor="" light={darkMode ? 1 : 0}>
                Project
              </ContactFormTag>
              <ContactTextArea
                name="message"
                required
                cols="30"
                rows="10"
                placeholder="Project description here"
                light={darkMode ? 1 : 0}
              />
            </ContactFormArea>

            <ButtonEmail type="submit" value="send" disabled={isSending}>
              {isSending ? 'Sending...' : 'Send Message'}
            </ButtonEmail>
          </ContactForm>
        </div>
      </ContactContainer>
    </ContactSection>
  );
};

export default Contact;
