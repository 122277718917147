import React, { useEffect, useRef, useState } from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { useScrollToBottom } from "../hooks/useScrollToBottom";
import PromptSession from "../classes/prompt-session";
import { useKeyboardInput } from "../hooks/useKeyboardInput";
import ResultDiv from "./ResultDiv";
import TerminalTitle from "./TerminalTitle";
import Prompt from "./Prompt";
import { motdText } from "../const/commands";
import MotdComponent from "../components/Motd";
import TerminalBottom from "./TerminalBottom";

const TerminalContainer = ({ handleTerminalChange }) => {
  const isMobile = useIsMobile();

  const [isMotdVisible, setIsMotdVisible] = useState(true);
  const [terminalClasses, setTerminalClasses] = useState(
    "scale-100 opacity-100"
  );

  const handleTerminalClose = () => {
    setTerminalClasses("scale-100 opacity-0");
    setIsMotdVisible(false)
    handleTerminalChange(false)
  }

  const bottomRef = useRef(null);
  useScrollToBottom(bottomRef.current);

  const [prompts, setPrompts] = useState([new PromptSession()]);



  const handleEnterPress = () => {
    if (promptText.trim().toLowerCase().split(" ")[0] === "clear") {
      setPrompts([new PromptSession()]);
      setIsMotdVisible(false);
      return;
    }

    setPrompts((prev) => {
      prev[prev.length - 1].handleEnterClick(promptText);
      return [...prev, new PromptSession()];
    });
  };

  const promptText = useKeyboardInput(handleEnterPress, () => {
    setPrompts([new PromptSession()]);
    setIsMotdVisible(false);
  });

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [prompts, promptText]);

  return (
    <section
      className={`rounded-md min-h-[60vh] max-h-[60vh]  md:min-w-[60vw]  md:max-w-[60vw] bg-kali-gray-dark/[.96] 
        border border-solid border-kali-border font-fira-code 
        text-sm shadow-terminal flex flex-col overflow-hidden 
        cursor-default resize ${terminalClasses} transition-all duration-100`}
    >
      {!isMobile && (
        <TerminalTitle
          closeTerminal={handleTerminalClose}
        />
      )}

      <div
        className="px-1 text-kali-gray text-sm w-full h-full
          overflow-y-auto terminal-scrollbar pb-12 pt-1"
      >
        {isMobile && (
          <ResultDiv
            text={`<span class="text-kali-red-dark">Err: Terminal mode does not work on devices without a physical keyboard.</span>`}
          />
        )}
        {/* {isMotdVisible && <ResultDiv text={motdText()} />} */}
        {isMotdVisible && <MotdComponent />}

        {prompts.map((prompt) => {
          return (
            <div key={`prompt-${Math.random()}`}>
              <Prompt
                text={prompt.enterPressed ? prompt.promptText : promptText}
                showCursor={prompt.showCursor}
              />

              {prompt.result !== undefined && (
                <ResultDiv text={prompt.result} />
              )}
            </div>
          );
        })}

        <div ref={bottomRef} />
      </div>
    </section>
  );
};

export default TerminalContainer;
