import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './components/utils/notFound/NotFound';
import CursorProvider from './CursorProvider';

function App({ darkMode }) {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<CursorProvider>
            <Home darkMode={darkMode} />
          </CursorProvider>} />
        </Routes>
      </BrowserRouter>

      <ToastContainer position="top-center" autoClose={1000} />
    </>
  );
}

export default App;
