// components/CursorProvider.jsx
import React from 'react';
import CustomCursor from '../src/utils/custom-cursor';
import CustomTrailCanvas from '../src/utils/custom-trail-canvas';

const CursorProvider = ({ children }) => {
    return (
        <>
            <CustomTrailCanvas className="pointer-events-none fixed inset-0 -z-10 h-full w-full" />
            <CustomCursor />
            {children}
        </>
    );
};

export default CursorProvider;
